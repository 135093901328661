import React from 'react';

import { Carousel } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import DynamicTag from '../utils/DynamicTag';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import "../styles/values.scss";

import Values576JpgOne from "../assets/images/values/values-01-576.jpg";
import Values576WebpOne from "../assets/images/values/values-01-576.webp";
import Values1280JpgOne from "../assets/images/values/values-01-1280.jpg";
import Values1280WebpOne from "../assets/images/values/values-01-1280.webp";

import Values576JpgTwo from "../assets/images/values/values-02-576.jpg";
import Values576WebpTwo from "../assets/images/values/values-02-576.webp";
import Values1280JpgTwo from "../assets/images/values/values-02-1280.jpg";
import Values1280WebpTwo from "../assets/images/values/values-02-1280.webp";

const Values2 = () => {
    return ( 
        <>
            <section className='values pt-xl-5 pb-sm-5'>
                <div className="container pb-3 pb-sm-0 pt-md-5 pb-sm-5">
                    <div className="row pt-5 pb-5 pb-sm-4 pb-xl-5" id='valores'>
                        <div className="row pt-5 d-block d-sm-none text-center mx-auto">
                            <div className="col-12">
                                <DynamicTag tagName="h2" text="Nuestros valores" classes='font-section-title'/>
                            </div>
                        </div>
                        <div className="col-12 pt-3 pt-sm-5 pb-5">
                            <div className='values--wrap ms-0 d-sm-flex align-items-center justify-content-center'>
                                <Carousel fade controls={false} className='values--wrap--carousel'>
                                    <Carousel.Item>
                                        <picture>
                                            <source
                                                type="image/webp"
                                                srcSet={`${Values576WebpOne} 576w, ${Values1280WebpOne} 1280w`}
                                            />
                                            <source
                                                type="image/jpeg"
                                                srcSet={`${Values576JpgOne} 576w, ${Values1280JpgOne} 1280w`}
                                            />
                                            <img
                                                className="values--carousel--image cover-image"
                                                src={Values1280JpgOne}
                                                alt="IJP Visión de la empresa"
                                            />
                                        </picture>
                                    </Carousel.Item>
                                
                                    <Carousel.Item>
                                        <picture>
                                            <source
                                                type="image/webp"
                                                srcSet={`${Values576WebpTwo} 576w, ${Values1280WebpTwo} 1280w`}
                                            />
                                            <source
                                                type="image/jpeg"
                                                srcSet={`${Values576JpgTwo} 576w, ${Values1280JpgTwo} 1280w`}
                                            />
                                            <img
                                                className="values--carousel--image cover-image"
                                                src={Values1280JpgTwo}
                                                alt="IJP Misión de la empresa"
                                            />
                                        </picture>
                                    </Carousel.Item>
                                </Carousel>

                                <div className="values--wrap--box d-flex align-items-center">

                                    <div className='values--wrap--box--nav-button values--wrap--box--nav-button__left'>
                                        <ChevronLeft className='' />
                                    </div>
                                    <div className='values--wrap--box--nav-button values--wrap--box--nav-button__right'>
                                        <ChevronRight className='' />
                                    </div>

                                    <Swiper
                                        slidesPerView={1}
                                        direction={"horizontal"}
                                        navigation={{
                                            nextEl: '.values--wrap--box--nav-button__right',
                                            prevEl: '.values--wrap--box--nav-button__left',
                                        }}
                                        modules={[Navigation]}
                                        className="values--wrap--box--swiper"
                                        >
                                        <SwiperSlide>
                                            <strong className='font-section-subtitle font-weight-500'>Visión</strong>
                                            <p className='font-section-text pt-4'>Ser empresa líder en servicio de transporte terrestre nacional, que brinda soluciones a las necesidades logisticas de nuestros clientes.</p>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <strong className='font-section-subtitle font-weight-500'>Misión</strong>
                                            <p className='font-section-text pt-4'>Proporcionar servicio de transporte terrestre de calidad, confiable, y segura.</p>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <strong className='font-section-subtitle font-weight-500'>Valores</strong>
                                            <ul className='font-section-text pt-4'>
                                                <li>Integridad</li>
                                                <li>Servicio</li>
                                                <li>Seguridad</li>
                                                <li>Responsabilidad</li>
                                            </ul>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}
 
export default Values2;
