import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

import "../styles/hero.scss";

import Hero1920Jpg from "../assets/images/hero/hero-1920.jpg";
import Hero1920Webp from "../assets/images/hero/hero-1920.webp";
import Hero1280Jpg from "../assets/images/hero/hero-1280.jpg";
import Hero1280Webp from "../assets/images/hero/hero-1280.webp";
import Hero768Jpg from "../assets/images/hero/hero-768.jpg";
import Hero768Webp from "../assets/images/hero/hero-768.webp";
import Hero576Jpg from "../assets/images/hero/hero-576.jpg";
import Hero576Webp from "../assets/images/hero/hero-576.webp";

const Hero = () => {

    const typedTextRef = useRef(null);
    const srcSetWebp = `${Hero576Webp} 576w, ${Hero768Webp} 768w, ${Hero1280Webp} 1280w, ${Hero1920Webp} 1920w`;
    const srcSetJpg = `${Hero576Jpg} 576w, ${Hero768Jpg} 768w, ${Hero1280Jpg} 1280w, ${Hero1920Jpg} 1920w`;

    useEffect(() => {

        const typedOptions = {
          strings: ['IJP Logística Móvil'],
          typeSpeed: 30,
          loopCount: 1,
          loop: true
        };
    
        const typed = new Typed(typedTextRef.current, typedOptions);
    
        return () => {
          typed.destroy();
        };
      }, []);

    return ( 
        <>
            <section className='hero' id="inicio">
                
                <picture className="hero--background-container">
                    <source type="image/webp" srcSet={srcSetWebp} />
                    <source type="image/jpeg" srcSet={srcSetJpg} />
                    <img
                        loading="lazy"
                        className="cover-image"
                        src={Hero1920Jpg}
                        alt="IJP Logística Móvil"
                    />
                </picture>


                <div className="hero d-flex justify-content-center align-items-center text-center">
                    <div className='container'>
                        <div className="row">
                            <div className="col-12">
                                <span className='hero--title color-white' ref={typedTextRef}></span>
                                <h1 className='visually-hidden'>IJP Logística Móvil</h1>
                                <p className='hero--text font-section-subtitle color-white mx-auto pt-sm-5'>En IJP Logística Móvil, somos una empresa 100% mexicana, dedicada a ofrecer soluciones logísticas nacionales e internacionales.</p>
                                <a href='https://wa.me/524792284645?text=Hola, ' target='_blank' rel="noreferrer" className='hero--badge d-inline-block mt-5'>Contacto</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}
 
export default Hero;