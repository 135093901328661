import React from 'react';

import { Award, Capslock, Calendar3 } from 'react-bootstrap-icons';

import DynamicTag from '../utils/DynamicTag';

import "../styles/coverage.scss";

import Coverage1920Jpg from "../assets/images/coverage/coverage-1920.jpg";
import Coverage1920Webp from "../assets/images/coverage/coverage-1920.webp";
import Coverage1280Jpg from "../assets/images/coverage/coverage-1280.jpg";
import Coverage1280Webp from "../assets/images/coverage/coverage-1280.webp";
import Coverage768Jpg from "../assets/images/coverage/coverage-768.jpg";
import Coverage768Webp from "../assets/images/coverage/coverage-768.webp";
import Coverage576Jpg from "../assets/images/coverage/coverage-576.jpg";
import Coverage576Webp from "../assets/images/coverage/coverage-576.webp";

const Coverage = () => {
    return ( 
        <>
            <section className='coverage pt-md-5 pb-xl-5' id='cobertura'>
                <div className="container">
                    <div className="row pb-3">
                        <div className="col-12 text-center">
                            <DynamicTag tagName="h2" text="Nuestra cobertura" classes='font-section-title'/>
                            <DynamicTag tagName="h3" text="Ofreciendo excelencia en todo momento" classes='font-section-subtitle mt-4' />

                            <picture>
                                <source
                                    type="image/webp"
                                    srcSet={`${Coverage576Webp} 576w, ${Coverage768Webp} 768w, ${Coverage1280Webp} 1280w, ${Coverage1920Webp} 1920w`}
                                />
                                <source
                                    type="image/jpeg"
                                    srcSet={`${Coverage576Jpg} 576w, ${Coverage768Jpg} 768w, ${Coverage1280Jpg} 1280w`}
                                />
                                <img
                                    loading="lazy"
                                    className="coverage--image pt-5 pt-xs-5 mt-xs-2 mt-lg-5"
                                    src={Coverage1920Jpg}
                                    alt="IJP Logística Móvil - Cobertura"
                                />
                            </picture>
                        </div>
                    </div>

                    <div className="row pt-4 pt-md-4 pt-lg-5">
                        <div className="col-12 pt-4 d-sm-flex justify-content-center">
                            <div className='coverage--items-box text-center'>
                                <Award className='coverage--items-box--icon d-block mx-auto' />
                                <strong className='coverage--items-box--title d-block pt-4'>Garantía</strong>
                                <p className='font-item-text d-block pt-4 mx-auto'>
                                    Contamos con operadores capacitados para cumplir con fechas y horarios de entrega.
                                </p>
                            </div>

                            <div className='coverage--items-box coverage--items-box__center text-center pt-5 pt-sm-0'>
                                <Capslock className='coverage--items-box--icon d-block mx-auto' />
                                <strong className='coverage--items-box--title d-block pt-4'>Alcance</strong>
                                <p className='font-item-text d-block pt-4 mx-auto'>
                                    Cubrimos rutas que abarcan todo el territorio nacional, incluyendo puertos, aeropuertos e importaciones y exportaciones a Nuevo Laredo.
                                </p>
                            </div>

                            <div className='coverage--items-box text-center pt-5 pt-sm-0'>
                                <Calendar3 className='coverage--items-box--icon d-block mx-auto' />
                                <strong className='coverage--items-box--title d-block pt-4'>Disponibilidad</strong>
                                <p className='font-item-text d-block pt-4 mx-auto'>
                                    Equipo de rastreo satelital en todas nuestras unidades. Servicio las 24hrs, los 365 días del año.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default Coverage;