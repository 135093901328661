import React from 'react';

import Card from 'react-bootstrap/Card';

import "../styles/services.scss"

import DynamicTag from '../utils/DynamicTag';

import Services576JpgOne from "../assets/images/services/services-01-576.jpg";
import Services576WebpOne from "../assets/images/services/services-01-576.webp";

import Services576JpgTwo from "../assets/images/services/services-02-576.jpg";
import Services576WebpTwo from "../assets/images/services/services-02-576.webp";

import Services576JpgThree from "../assets/images/services/services-03-576.jpg";
import Services576WebpThree from "../assets/images/services/services-03-576.webp";

import Services576JpgFour from "../assets/images/services/services-04-576.jpg";
import Services576WebpFour from "../assets/images/services/services-04-576.webp";

import Services576JpgFive from "../assets/images/services/services-05-576.jpg";
import Services576WebpFive from "../assets/images/services/services-05-576.webp";

const Services = () => {
    return ( 
        <>
            <section className='services pt-md-5' id='servicios'>
                <div className="container pt-md-5 pb-5">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <DynamicTag tagName="h2" text="Nuestros servicios" classes='font-section-title'/>
                        </div>
                        <div className="col-12 col-md-7 mt-4 mt-md-0">
                            <p className='font-section-text'>
                                Contamos con personal capacitado para garantizar entregas puntuales en  rutas nacionales, importaciones y exportaciones. Nuestras unidades están  equipadas con rastreo satelital y ofrecemos seguro de riesgo para  proteger sus envíos. Disponible 24/7, 365 días al año.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="services--grid pt-5 d-none d-xl-block">
                    <div className="services--grid--column col-5 d-flex text-center align-items-center">
                        <picture>
                            <source
                                type="image/webp" srcSet={`${Services576WebpOne}`}
                            />
                            <source
                                type="image/jpeg" srcSet={`${Services576JpgOne}`}
                            />
                            <img
                                loading="lazy" 
                                className="services--grid--column--image cover-image"
                                src={Services576JpgOne}
                                alt="Servicios Nacionales"
                            />
                        </picture>
                        
                        <div className='services--grid--column--box'>
                            <strong className='services--grid--column--box--title d-block'>Servicios Nacionales</strong>
                            <p className='services--grid--column--box--text mb-0'>
                                Atendemos las principales rutas nacionales incluyendo ingreso a aduanas con equipo propio desde vehiculo utilitario tipo sedan, 1 tonelada, 3.5 toneladas, Rabon, Torton y Cajas de 53. 
                            </p>
                        </div>
                    </div>
                    <div className="services--grid--column col-5 d-flex text-center align-items-center">
                        <picture>
                            <source
                                type="image/webp" srcSet={`${Services576WebpTwo}`}
                            />
                            <source
                                type="image/jpeg" srcSet={`${Services576JpgTwo}`}
                            />
                            <img
                                loading="lazy" 
                                className="services--grid--column--image cover-image"
                                src={Services576JpgTwo}
                                alt="Importaciones y Exportaciones"
                            />
                        </picture>

                        <div className='services--grid--column--box'>
                            <strong className='services--grid--column--box--title d-block'>Importaciones y Exportaciones</strong>
                            <p className='services--grid--column--box--text mb-0'>
                                Cumplimos con la exigencia de nuestros clientes ampliando nuestra cobertura con rutas de exportación e importación desde Laredo tx. 
                            </p>
                        </div>
                    </div>

                    <div className="services--grid--column col-5 d-flex text-center align-items-center">
                        <picture>
                            <source
                                type="image/webp" srcSet={`${Services576WebpThree}`}
                            />
                            <source
                                type="image/jpeg" srcSet={`${Services576JpgThree}`}
                            />
                            <img
                                loading="lazy" 
                                className="services--grid--column--image cover-image"
                                src={Services576JpgThree}
                                alt="Hand Carry / On Board Courier"
                            />
                        </picture>

                        <div className='services--grid--column--box'>
                            <strong className='services--grid--column--box--title d-block'>Hand Carry / On Board Courier</strong>
                            <p className='services--grid--column--box--text mb-0'>
                                La solución para las cargas más críticas e importantes. Entrega y recolección personal con el máximo nivel de seguridad y eficacia.
                            </p>
                        </div>
                    </div>

                    <div className="services--grid--column col-5 d-flex text-center align-items-center">
                        <picture>
                            <source
                                type="image/webp" srcSet={`${Services576WebpFour}`}
                            />
                            <source
                                type="image/jpeg" srcSet={`${Services576JpgFour}`}
                            />
                            <img
                                loading="lazy" 
                                className="services--grid--column--image cover-image"
                                src={Services576JpgFour}
                                alt="Paquetería"
                            />
                        </picture>

                        <div className='services--grid--column--box'>
                            <strong className='services--grid--column--box--title d-block'>Paquetería</strong>
                            <p className='services--grid--column--box--text mb-0'>
                                Recolectamos y entregamos tu mercancía de pequeño volumen a bordo de nuestras unidades utilitarias para su distribución a las principales ciudades del territorio nacional.  
                            </p>
                        </div>
                    </div>

                    <div className="services--grid--column col-5 d-flex text-center align-items-center">
                        <picture>
                            <source
                                type="image/webp" srcSet={`${Services576WebpFive}`}
                            />
                            <source
                                type="image/jpeg" srcSet={`${Services576JpgFive}`}
                            />
                            <img
                                loading="lazy" 
                                className="services--grid--column--image cover-image"
                                src={Services576JpgFive}
                                alt="Seguro"
                            />
                        </picture>

                        <div className='services--grid--column--box'>
                            <strong className='services--grid--column--box--title d-block'>Seguro</strong>
                            <p className='services--grid--column--box--text mb-0'>
                                Nos preocupamos por tu mercancía y les brindamos el beneficio de contratar un seguro de carga que se adapte a tus necesidades para así cubrir el riesgo y estabilidad financiera contra pérdidas o daños causados en el traslado.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className="services--cards container d-block d-xl-none">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 mt-5 mt-md-4">
                            <Card className='services--cards--card mx-auto'>
                                <picture>
                                    <source
                                        type="image/webp" srcSet={`${Services576WebpOne}`}
                                    />
                                    <source
                                        type="image/jpeg" srcSet={`${Services576JpgOne}`}
                                    />
                                    <img
                                        loading="lazy" 
                                        className="services--cards--card--image"
                                        src={Services576JpgOne}
                                        alt="Importaciones y Exportaciones"
                                    />
                                </picture>
                                <Card.Body>
                                    <Card.Title className='services--cards--card--title pt-2'>Importaciones y Exportaciones</Card.Title>
                                    <Card.Text className='font-item-text w-100 pt-2'>
                                        Cumplimos con la exigencia de nuestros clientes ampliando nuestra cobertura con rutas de exportación e importación desde Laredo tx. 
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 mt-5 mt-md-4">
                            <Card className='services--cards--card mx-auto'>
                                <picture>
                                    <source
                                        type="image/webp" srcSet={`${Services576WebpTwo}`}
                                    />
                                    <source
                                        type="image/jpeg" srcSet={`${Services576JpgTwo}`}
                                    />
                                    <img
                                        loading="lazy" 
                                        className="services--cards--card--image"
                                        src={Services576JpgTwo}
                                        alt="Servicios nacionales"
                                    />
                                </picture>
                                <Card.Body>
                                    <Card.Title className='services--cards--card--title pt-2'>Servicios nacionales</Card.Title>
                                    <Card.Text className='font-item-text w-100 pt-2'>
                                        Atendemos las principales rutas nacionales incluyendo ingreso a aduanas con equipo propio desde vehiculo utilitario tipo sedan, 1 tonelada, 3.5 toneladas, Rabon, Torton y Cajas de 53. 
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 mt-5 mt-md-4">
                            <Card className='services--cards--card mx-auto'>
                                <picture>
                                    <source
                                        type="image/webp" srcSet={`${Services576WebpThree}`}
                                    />
                                    <source
                                        type="image/jpeg" srcSet={`${Services576JpgThree}`}
                                    />
                                    <img
                                        loading="lazy" 
                                        className="services--cards--card--image"
                                        src={Services576JpgThree}
                                        alt="Hand Carry / On Board Courier"
                                    />
                                </picture>
                                <Card.Body>
                                    <Card.Title className='services--cards--card--title pt-2'>Hand Carry / On Board Courier</Card.Title>
                                    <Card.Text className='font-item-text w-100 pt-2'>
                                        La solución para las cargas más críticas e importantes. Entrega y recolección personal con el máximo nivel de seguridad y eficacia.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 mt-5 mt-md-4 mt-lg-5">
                            <Card className='services--cards--card mx-auto'>
                                <picture>
                                    <source
                                        type="image/webp" srcSet={`${Services576WebpFour}`}
                                    />
                                    <source
                                        type="image/jpeg" srcSet={`${Services576JpgFour}`}
                                    />
                                    <img
                                        loading="lazy" 
                                        className="services--cards--card--image"
                                        src={Services576JpgFour}
                                        alt="Paquetería"
                                    />
                                </picture>
                                <Card.Body>
                                    <Card.Title className='services--cards--card--title pt-2'>Paquetería</Card.Title>
                                    <Card.Text className='font-item-text w-100 pt-2'>
                                        Recolectamos y entregamos tu mercancía de pequeño volumen a bordo de nuestras unidades utilitarias para su distribución a las principales ciudades del territorio nacional.  
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 mt-5 mt-md-4 mt-lg-5">
                            <Card className='services--cards--card mx-auto'>
                                <picture>
                                    <source
                                        type="image/webp" srcSet={`${Services576WebpFive}`}
                                    />
                                    <source
                                        type="image/jpeg" srcSet={`${Services576JpgFive}`}
                                    />
                                    <img
                                        loading="lazy" 
                                        className="services--cards--card--image"
                                        src={Services576JpgFive}
                                        alt="Five"
                                    />
                                </picture>
                                <Card.Body>
                                    <Card.Title className='services--cards--card--title pt-2'>Seguro</Card.Title>
                                    <Card.Text className='font-item-text w-100 pt-2'>
                                        Nos preocupamos por tu mercancía y les brindamos el beneficio de contratar un seguro de carga que se adapte a tus necesidades para así cubrir el riesgo y estabilidad financiera contra pérdidas o daños causados en el traslado.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

            </section>
        </>
     );
}
 
export default Services;