import React from 'react';

import { GeoAlt, Whatsapp, Envelope } from 'react-bootstrap-icons';

import "../styles/contact.scss";

import DynamicTag from '../utils/DynamicTag';

const Contact = () => {
    return ( 
        <>
            <section className='contact pt-5 pb-5' id='contacto'>
                <div className="container pt-md-5 pb-5">
                    <div className="row mx-auto text-lg-center pb-xl-5">
                        <div className="col-5 d-none d-lg-block">
                            <div className='contact--map'>
                                <iframe title="IJP Logistica Movil Ubicacion" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3720.573572872189!2d-101.65490307473952!3d21.16936203051537!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bb92d116d351f%3A0xd3c35846f3ab128f!2sBrisas%20del%20Lago%2C%2037207%20Leon%2C%20Guanajuato!5e0!3m2!1sen!2smx!4v1712437488891!5m2!1sen!2smx" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7 ps-lg-4">
                            <div className="row">
                                <div className="col-12">
                                    <DynamicTag tagName="h2" text="Contacto" classes='font-section-title'/>
                                    <p className='contact--text font-section-text pt-4 mx-lg-auto'>Comuníquese con nosotros para mayor información o consultas personalizadas.</p>
                                </div>
                            </div>
                            <div className="row d-lg-none mt-4">
                                <div className="col-12">
                                    <div className='contact--map'>
                                        <iframe title="IJP Logistica Movil Ubicacion" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3720.573572872189!2d-101.65490307473952!3d21.16936203051537!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bb92d116d351f%3A0xd3c35846f3ab128f!2sBrisas%20del%20Lago%2C%2037207%20Leon%2C%20Guanajuato!5e0!3m2!1sen!2smx!4v1712437488891!5m2!1sen!2smx" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-md-4 text-center">
                                <div className="col-12 col-md-4 pt-5 pt-lg-0">
                                    <GeoAlt className='contact--icon' />
                                    <span className='contact--icon-text d-block pt-3 mx-auto'>Circuito Lago Cuquio 171-A Col.  Brisas de lago, Leon, Guanajuato</span>
                                </div>

                                <div className="col-6 col-md-4 pt-5 pt-lg-0">
                                    <Whatsapp className='contact--icon' />
                                    <a href='https://wa.me/524774291879?text=Hola, ' className='contact--badge d-block pt-3' target="_blank" rel="noreferrer">+52 477 429 1879</a>
                                    <a href='https://wa.me/524792284645?text=Hola, ' className='contact--badge d-block pt-1' target="_blank" rel="noreferrer">+52 479 228 4645</a>
                                </div>

                                <div className="col-6 col-md-4 pt-5 pt-lg-0">
                                    <Envelope className='contact--icon' />
                                    <a href='mailto:administracion@ijp-logistica.com' className='contact--badge d-block pt-3'>administracion@ijp-logistica.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}
 
export default Contact;