import React, { useState, useEffect } from 'react';
import "../styles/myNavbar.scss";

import { Navbar, Container, Nav } from 'react-bootstrap';

import { Facebook, Linkedin, Instagram } from 'react-bootstrap-icons';

import Headroom from 'react-headroom';
import Hamburger from 'hamburger-react'

import IsotipoPng from "../assets/images/logo/completo.png";

const MyNavbar = () => {
    const [expanded, setExpanded] = useState(false);
    const [hamburgerActive, setHamburgerActive] = useState(false);

    const handleToggleClick = () => {
        setExpanded(!expanded); // Cambia el estado de 'expanded' al hacer clic en el Navbar.Toggle
        setHamburgerActive(!hamburgerActive); // Cambia el estado del Hamburger al hacer clic en el Navbar.Toggle
    };

    const handleLinkClick = () => {
        setExpanded(false); // Cierra el Navbar al hacer clic en un enlace
        setHamburgerActive(false); // Cierra el Hamburger al hacer clic en un enlace
    };

    useEffect(() => {
        const hamburgerElement = document.querySelector('.hamburger-react');
        if (hamburgerElement) {
          hamburgerElement.setAttribute('aria-label', 'Menu');
        }
    }, []);
    
    return ( 
        <>
            <Headroom
                onPin={() => console.log('pinned')}
                onUnpin={() => console.log('unpinned')}
                style={{
                }}
                >
                <Navbar expanded={expanded} expand="lg" className="my-navbar">
                    <Container>
                        <Navbar.Brand className='ms-3 ms-md-0' href="#inicio">
                            <img src={IsotipoPng} alt="IJP Logo" className="my-navbar--logo d-inline-block align-text-top" />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-label="Hamburguer button" onClick={handleToggleClick} aria-controls="basic-navbar-nav" >
                            <Hamburger aria-label="Hamburguer button" toggled={hamburgerActive} toggle={setHamburgerActive} />
                        </Navbar.Toggle>

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                                <Nav.Link onClick={handleLinkClick} className='my-navbar--item mt-2 mt-lg-0 ms-3 ms-xl-5' href="#cobertura">Cobertura</Nav.Link>
                                <Nav.Link onClick={handleLinkClick} className='my-navbar--item ms-3 ms-xl-5' href="#valores">Valores</Nav.Link>
                                <Nav.Link onClick={handleLinkClick} className='my-navbar--item ms-3 ms-xl-5' href="#servicios">Servicios</Nav.Link>
                                <Nav.Link onClick={handleLinkClick} className='my-navbar--item ms-3 ms-xl-5' href="#contacto">Contacto</Nav.Link>
                            </Nav>

                            <Nav className='ms-auto'>
                                <div className='mt-2 mt-lg-0 mb-3 mb-lg-0'>
                                    <a onClick={handleLinkClick} className='my-navbar--item my-navbar--item__icon ms-3 ms-xl-5 d-inline' href="https://www.linkedin.com/in/ijp-logistica-movil-b79035214/?originalSubdomain=mx" target="_blank" rel="noreferrer" aria-label="IJP Logistica Movil Linkedin">
                                        <Linkedin />
                                    </a>
                                    <a onClick={handleLinkClick} className='my-navbar--item my-navbar--item__icon ms-3 ms-xl-5 d-inline' href="https://www.instagram.com/ijp_logistica/" target="_blank" rel="noreferrer" aria-label="IJP Logistica Movil Instagram">
                                        <Instagram />
                                    </a>
                                    <a onClick={handleLinkClick} className='my-navbar--item my-navbar--item__icon ms-3 ms-xl-5 d-inline' href="https://www.facebook.com/IJPLOGISTICAMOVIL/?locale=es_LA" target="_blank" rel="noreferrer" aria-label="IJP Logistica Movil Facebook">
                                        <Facebook />
                                    </a>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Headroom>
        </>
     );
}
 
export default MyNavbar;