import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';

import { Facebook, Linkedin, Instagram } from 'react-bootstrap-icons';

import "../styles/footer.scss"

const Footer = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return ( 
        <>
            <footer className='footer'>
                <div className="container pt-5 pb-5">
                    <div className="row">
                        <div className="col-12 d-md-flex text-center justify-content-center">
                            <div className='pe-md-5'>
                                <span onClick={handleShow} className='footer--badge color-white'>Políticas de privacidad</span>

                                <Modal show={show} onHide={handleClose} size="lg" scrollable>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Políticas de Privacidad</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                                        
                                        <br />
                                        <h3>Recopilación y uso de la información</h3>

                                        <p>Recopilamos varios tipos diferentes de información para diversos fines para proporcionar y mejorar nuestro Servicio.</p>

                                        <h4>Tipos de datos recopilados</h4>

                                        <p><strong>Datos personales</strong></p>

                                        <p>Al utilizar nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede utilizar para contactarlo o identificarlo  . La información de identificación personal puede incluir, pero no se limita a:</p>

                                        <ul>
                                            <li>Dirección de correo electrónico</li>
                                            <li>Nombre y apellido</li>
                                            <li>Número de teléfono</li>
                                            <li>Dirección, Estado, Provincia, Código postal, Ciudad</li>
                                            <li>Cookies y datos de uso</li>
                                        </ul>

                                        <p><strong>Datos de uso</strong></p>

                                        <p>También podemos recopilar información sobre cómo se accede y se utiliza el Servicio . Estos Datos de uso pueden incluir información como la dirección del Protocolo de Internet de su computadora (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas, identificadores únicos de dispositivos y otros datos de diagnóstico.</p>

                                        <h4>Seguimiento y cookies de datos</h4>

                                        <p>Utilizamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro Servicio y mantener cierta información.</p>

                                        <p>Las cookies son archivos con una pequeña cantidad de datos que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se almacenan en su dispositivo. También se utilizan otras tecnologías de seguimiento, como balizas, etiquetas y scripts, para recopilar y rastrear información y para mejorar y analizar nuestro Servicio.</p>

                                        <p>Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si no acepta cookies, es posible que no pueda usar algunas partes de nuestro Servicio.</p>

                                        <p>Ejemplos de cookies que utilizamos:</p>

                                        <ul>
                                            <li>Cookies de sesión. Utilizamos cookies de sesión para operar nuestro Servicio.</li>
                                            <li>Cookies de preferencias. Utilizamos cookies de preferencias para recordar sus preferencias y diversas configuraciones.</li>
                                        </ul>

                                        <h4>Uso de datos</h4>

                                        <p>IJP  utiliza los datos recopilados para diversos fines:</p>

                                        <ul>
                                            <li>Para proporcionar y mantener nuestro Servicio</li>
                                            <li>Para notificarle sobre cambios en nuestro Servicio</li>
                                            <li>Para permitirle participar en funciones interactivas de nuestro Servicio cuando decida hacerlo</li>
                                            <li>Para proporcionar asistencia al cliente</li>
                                            <li>Para recopilar análisis o información valiosa para que podamos mejorar nuestro Servicio</li>
                                            <li>Para controlar el uso de nuestro Servicio</li>
                                            <li>Para detectar, prevenir y abordar problemas técnicos</li>
                                        </ul>

                                        <h3>Transferencia de datos</h3>

                                        <p>Su información, incluidos los Datos personales, puede transferirse a —y mantenerse en— computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.</p>

                                        <p>Si se encuentra fuera de  y elige proporcionarnos información, tenga en cuenta que transferimos los datos, incluidos los Datos personales, a  y los procesamos allí.</p>

                                        <p>Su consentimiento a esta Política de Privacidad seguido de su envío de dicha información representa su aceptación de esa transferencia.</p>

                                        <p>IJP  tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de forma segura y de conformidad con esta Política de Privacidad y no se realizará ninguna transferencia de sus Datos personales a una organización o país a menos que existan controles adecuados establecidos, incluida la seguridad de sus datos y otra información personal.</p>

                                        <h3>Divulgación de datos</h3>

                                        <h4>Requisitos legales</h4>

                                        <p>IJP  puede divulgar sus Datos personales de buena fe cuando considere que esta acción es necesaria para:</p>

                                        <ul>
                                            <li>Cumplir con una obligación legal</li>
                                            <li>Proteger y defender los derechos o bienes de IJP </li>
                                            <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
                                            <li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>
                                            <li>Protegerse contra la responsabilidad legal</li>
                                        </ul>

                                        <h3>Seguridad de los datos</h3>

                                        <p>La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos personales, no podemos garantizar su seguridad absoluta.</p>

                                        <h3>Proveedores de servicios</h3>

                                        <p>Podemos emplear empresas e individuos de terceros para facilitar nuestro Servicio, para proporcionar el Servicio en nuestro nombre, para realizar servicios relacionados con el Servicio o para ayudarnos a analizar cómo se utiliza nuestro Servicio.</p>

                                        <p>Estos terceros tienen acceso a sus Datos personales solo para realizar estas tareas en nuestro nombre y están obligados a no divulgarlos ni utilizarlos para ningún otro propósito.</p>


                                    </Modal.Body>
                                </Modal>
                            </div>
                            <div className="pb-4 pb-md-0 pt-4 pt-md-0 ps-md-5 pe-md-5">
                                <strong className='footer--title d-block color-white'>® IJP Logística Móvil</strong>
                                <br />
                                <a className='footer--badge color-white' href="mailto:soporte@thebrand.com.mx">By The Brand</a>
                            </div>
                            <div className="ps-md-5 pt-2 pt-md-0">
                                <a href="https://www.facebook.com/IJPLOGISTICAMOVIL/?locale=es_LA" target="_blank" rel="noreferrer" aria-label="IJP Logistica Movil Facebook"><Facebook className='footer--icon color-white d-inline' /></a>
                                <a href="https://www.linkedin.com/in/ijp-logistica-movil-b79035214/?originalSubdomain=mx" className='ms-4' target="_blank" rel="noreferrer" aria-label="IJP Logistica Movil Linkedin"><Linkedin className='footer--icon color-white d-inline' /></a>
                                <a href="https://www.instagram.com/ijp_logistica/" className='ms-4' target="_blank" rel="noreferrer" aria-label="IJP Logistica Movil Instagram"><Instagram className='footer--icon color-white d-inline' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
     );
}
 
export default Footer;