import React from 'react';

import MyNavbar from "../components/MyNavbar";
import Hero from "../components/Hero";
import Coverage from "../components/Coverage";
import Values from "../components/Values";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

import TopPadding from "../utils/TopPadding";

/* Images */
import Isotipo64Ico from "../assets/images/logo/isotipo-square-64.ico";
import Isotipo320Png from "../assets/images/logo/isotipo-square-320.png";
import Isotipo180Png from "../assets/images/logo/isotipo-square-180.png";
import Hero1280Jpg from "../assets/images/hero/hero-1280.jpg";

const Home = () => {
    return ( 
        <>
            <MyNavbar />
            <Hero />
            <TopPadding padding = "pt-5 mt-5" />
            <Coverage />
            <TopPadding padding = "pt-5 mt-5" />
            <Values />
            <TopPadding padding = "pt-5 mt-5" />
            <Services />
            <TopPadding padding = "pt-5 mt-5" />
            <Contact />
            <Footer />

            <img src={Isotipo64Ico} alt="ISO" className='d-none'/>
            <img src={Isotipo320Png} alt="ISO" className='d-none'/>
            <img src={Isotipo180Png} alt="ISO" className='d-none'/>
            <img src={Hero1280Jpg} alt="ISO" className='d-none'/>
        </>
     );
}
 
export default Home;