import React from 'react';

const DynamicTag = ({ tagName, text, classes = '' }) => {
    const Tag = tagName || 'span';
  
    return (
      <Tag className={classes}> {text} </Tag>
    );
};

export default DynamicTag;